import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { BlDefaultButton } from "Shared/Buttons/BlDefaultButton";
import { BoxWithCheckIcon, SettingsIcon } from "Shared/Icons";
import { TopBarTitle } from "Shared/Layouts/AuthenticatedLayout";
import { useNavigate } from "react-router-dom";

const StyledIconsWrapper = styled(Box)`
  svg {
    margin-bottom: 3px;
  }
`;
const PageResource = Resources.Notices;

export const TopBar = () => {
	const { t } = useResource();

	const navigate = useNavigate();

	return (
		<Grid container alignItems="center" mr={1}>
			<Grid item xs={1.5}>
				<BlDefaultButton color="inherit" onClick={() => navigate(-1)}>
					<ArrowLeftIcon />
				</BlDefaultButton>
			</Grid>
			<Grid item xs textAlign="left">
				<TopBarTitle fontWeight={700} fontSize={16} textAlign="left">
					{t(PageResource.Title)}
				</TopBarTitle>
			</Grid>
			<Grid
				item
				xs
				display="flex"
				justifyContent={"flex-end"}
				alignItems={"flex-end"}
			>
				<StyledIconsWrapper>
					<BlDefaultButton color="inherit">
						<BoxWithCheckIcon />
					</BlDefaultButton>
				</StyledIconsWrapper>
				<StyledIconsWrapper>
					<BlDefaultButton color="inherit">
						<SettingsIcon />
					</BlDefaultButton>
				</StyledIconsWrapper>
			</Grid>
		</Grid>
	);
};
