import { Badge, Box, Grid, styled } from "@mui/material";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { AppRouting } from "Infrastructure/Utils/UrlUtils";
import { unseenNoticeCountAtom } from "Notices/State/NoticesHubAtoms";
import { BlDefaultButton } from "Shared/Buttons/BlDefaultButton";
import { BellIcon, SearchIcon, SettingsIcon } from "Shared/Icons";
import { TopBarTitle } from "Shared/Layouts/AuthenticatedLayout";
import { UnstyledLink } from "Shared/Routing/UnstyledLink";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";

const PageResources = Resources.Tasks.Dashboard;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 3px;
    right: 3px;
    font-weight: 700;
    font-size: 8px;
    height: 18px;
    width: 18px;
  }
`;

const StyledWrapper = styled(Grid)`
  svg {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const TopBar: React.FunctionComponent = () => {
	const { t } = useResource();

	const [notificationCount] = useAtom(unseenNoticeCountAtom);

	return (
		<StyledWrapper container alignItems="center" pl={2} pr={2}>
			<Grid item xs>
				<SearchIcon />
			</Grid>
			<Grid item xs>
				<TopBarTitle fontWeight={700} fontSize={16} textAlign="center">
					{t(PageResources.Title)}
				</TopBarTitle>
			</Grid>
			<Grid
				item
				xs
				display="flex"
				justifyContent={"flex-end"}
				alignItems={"flex-end"}
			>
				<Box mr={1}>
					<UnstyledLink to={AppRouting.Notifications.path()}>
						<BlDefaultButton color="inherit">
							{notificationCount > 0 ? (
								<StyledBadge badgeContent={notificationCount} color="error">
									<BellIcon />
								</StyledBadge>
							) : (
								<BellIcon />
							)}
						</BlDefaultButton>
					</UnstyledLink>
				</Box>
				<Box>
					<BlDefaultButton color="inherit">
						<SettingsIcon />
					</BlDefaultButton>
				</Box>
			</Grid>
		</StyledWrapper>
	);
};
