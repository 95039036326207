
/* eslint-disable */
// THIS FILE WAS GENERATED
// ALL CHANGES WILL BE OVERWRITTEN

// ARCHITECTURE START
  type StandardError = globalThis.Error;
  type Error500s = 501 | 502 | 503 | 504 | 505 | 506 | 507 | 508 | 510 | 511;
  type ErrorStatuses = 0 | Error500s;
  export type ErrorResponse = FetchResponse<unknown, ErrorStatuses> 

  export type FetchResponseOfError = {
    data: null;
    error: StandardError;
    status: ErrorStatuses;
    args: any;
  };

  export type FetchResponseOfSuccess<TData, TStatus extends number = 0> = 
  {
    data: TData;
    error: null;
    status: TStatus;
    args: any;
    responseHeaders: Headers;
  }

  export type FetchResponse<TData, TStatus extends number = 0> = 
    TStatus extends ErrorStatuses ? FetchResponseOfError: FetchResponseOfSuccess<TData, TStatus>;

  type Configuration = {
    jwtKey: string | undefined;
    onResponse?: (response: FetchResponse<unknown, any>) => void;
  };
  
  let CONFIG: Configuration = {
    jwtKey: undefined,
    onResponse: () => {},
  };
  
  export function configureApiCalls(configuration: Configuration) {
    CONFIG = { ...CONFIG, ...configuration };
  }
  
  async function fetchJson<T extends FetchResponse<unknown, number>>(...args: any): Promise<T> {
    const errorResponse = (error: StandardError, status: number, args: any) => {
      const errorResponse = { status: status as ErrorStatuses, args, data: null, error } satisfies FetchResponse<T>;
      CONFIG.onResponse && CONFIG.onResponse(errorResponse);
      return errorResponse as unknown as T;
    }

    const errorStatus = (args: any) => {
      const errorResponse = { status: 0, args, data: null, error: new Error("Network error") } as FetchResponse<T, Error500s>;
      CONFIG.onResponse && CONFIG.onResponse(errorResponse);
      return errorResponse as unknown as T;
    }

    try {
      const res: Response = await (fetch as any)(...args);
      const status = res.status;
      try {
        const json = await res.json();
        const response = { data: json, status: res.status, args, error: null, responseHeaders: res.headers };
        CONFIG.onResponse && CONFIG.onResponse(response);
        return response as unknown as T;
      }
      catch (error){
        return errorResponse(error as StandardError, status, args)
      }
    } catch {
      return errorStatus(args);
    }
  }
  
  const updateHeaders = (headers: Headers) => {
    if (!headers.has("Content-Type")) {
      headers.append("Content-Type", "application/json");
    }
    const token = CONFIG.jwtKey
      ? localStorage.getItem(CONFIG.jwtKey as any)
      : undefined;
    if (!headers.has("Authorization") && token) {
      headers.append("Authorization", token);
    }
  };

function apiPost<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  var raw = JSON.stringify(request);
  updateHeaders(headers);
  var requestOptions = {
    method: "POST",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}

type ParamsObject = {
  [key: string]: any;
};

function apiGet<TResponse extends FetchResponse<unknown, number>>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);
  const queryString = Object.entries(paramsObject)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map(val => `${encodeURIComponent(key)}=${encodeURIComponent(
            val,
          )}`)
          .join('&');
      }
      // Handling non-array parameters
      return value !== undefined && value !== null 
        ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` 
        : '';
    })
    .filter(part => part !== '')
    .join("&");
  const maybeQueryString = queryString.length > 0 ? `?${queryString}` : "";
  const requestOptions = {
    method: "GET",
    headers,
    redirect: "follow",
  };
  return fetchJson<TResponse>(`${url}${maybeQueryString}`, requestOptions);
}

function apiPut<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  updateHeaders(headers);

  var raw = JSON.stringify(request);

  var requestOptions = {
    method: "PUT",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}

function apiDelete<TResponse extends FetchResponse<unknown, number>>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);
  const queryString = Object.entries(paramsObject)
    .filter(([_, val]) => val !== undefined && val !== null)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  const maybeQueryString = queryString.length > 0 ? `?${queryString}` : "";

  var requestOptions = {
    method: "DELETE",
    headers,
    redirect: "follow",
  };
  return fetchJson<TResponse>(`${url}${maybeQueryString}`, requestOptions);
}

function apiPatch<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  updateHeaders(headers);

  var raw = JSON.stringify(request);

  var requestOptions = {
    method: "PATCH",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}
// ARCHITECTURE END

export const API_ROUTES = { 
	postUsersFcmToken: "/api/v1/users/fcm-token",
	deleteUsersFcmTokenRequest: "/api/v1/users/fcm-token",
	getNoticesUserNoticeList: "/api/v1/notices/user-notice-list",
	putNoticesUpdateDisplayStatusInUserNotice: "/api/v1/notices/update-display-status-in-user-notice",
	putNoticesSetSeeForAllNotSeenUserNotices: "/api/v1/notices/set-see-for-all-not-seen-user-notices",
	postEnterprises: "/api/v1/enterprises",
	getCodeLists: "/api/v1/code-lists",
	postCacheRefresh: "/api/v1/cache/refresh",
	postAuthSignIn: "/api/v1/auth/sign-in",
	postAuthSso: "/api/v1/auth/sso",
	getAuthUserinfo: "/api/v1/auth/userinfo"
}

export type ApiProblemDetails = {
	errors: {[key: string | number]: ErrorDetail[]};
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export type ErrorDetail = {
	code: ErrorCode;
	message?: string | null;
};

export enum ErrorCode {
	Unspecified = "Unspecified",
	OutOfRange = "OutOfRange",
	NotFound = "NotFound",
	Invalid = "Invalid",
	Forbidden = "Forbidden",
	TooManyRequests = "TooManyRequests",
	Conflict = "Conflict",
	NullOrEmpty = "NullOrEmpty",
	Unauthorized = "Unauthorized",
	ExternalProviderNotAvailable = "ExternalProviderNotAvailable"
};

export type ProblemDetails = {
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export type RegisterFcmTokenCommandResult = {
	status: RegisterFcmTokenCommandResultStatus;
};

export enum RegisterFcmTokenCommandResultStatus {
	Success = "Success",
	AlreadyRegistered = "AlreadyRegistered"
};

export type RegisterFcmTokenRequest = {
	token: string;
};

export type DeactivateFcmTokenCommandResult = {
	status: DeactivateFcmTokenCommandResultStatus;
};

export enum DeactivateFcmTokenCommandResultStatus {
	Success = "Success",
	AlreadyInactive = "AlreadyInactive",
	NotFoundToken = "NotFoundToken"
};

export type DeactivateFcmTokenRequest = {
	token: string;
};

export type EntityListOfUserNoticeListItemDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: UserNoticeListItemDto[];
};

export type UserNoticeListItemDto = {
	publicID: string;
	type: NoticeType;
	value?: string | null;
	previousValue?: string | null;
	entityName: string;
	entityPublicID: string;
	displayStatus: UserNoticeDisplayStatus;
	readDate?: string | null;
	seenDate?: string | null;
	dateCreated: string;
};

export enum NoticeType {
	CommentaryAdded = "CommentaryAdded",
	AssignedToUser = "AssignedToUser",
	MentionedInComment = "MentionedInComment",
	WorkflowStepResolved = "WorkflowStepResolved"
};

export enum UserNoticeDisplayStatus {
	New = "New",
	Seen = "Seen",
	Read = "Read",
	Dismissed = "Dismissed"
};

export type UpdateDisplayStatusInUserNoticeCommandResult = {};

export type UpdateDisplayStatusInUserNoticeRequest = {
	displayStatus: UserNoticeDisplayStatus;
	entityPublicID: string;
	entityName: string;
	includedTypes: NoticeType[];
};

export type SetSeeForAllNotSeenUserNoticesRequest = {};

export type CreateEnterpriseRequest = {
	externalID: string;
	name: string;
	designSetting?: EnterpriseDesignSettingDto | null;
	companies?: EnterpriseCompaniesDto | null;
};

export type EnterpriseDesignSettingDto = {
	color: string;
	logoBase64?: string | null;
};

export type EnterpriseCompaniesDto = {
	default: EnterpriseCompanyDto;
	others: EnterpriseCompanyDto[];
};

export type EnterpriseCompanyDto = {
	externalId: string;
	invoiceNumberSeries?: number | null;
	designSetting?: EnterpriseCompanyDesignSettingDto | null;
	party: EnterprisePartyDto;
};

export type EnterpriseCompanyDesignSettingDto = {
	logoBase64?: string | null;
};

export type EnterprisePartyDto = {
	type: PartyType;
	firstName?: string | null;
	lastName?: string | null;
	companyName?: string | null;
	taxNumber?: string | null;
	companyNumber?: string | null;
	personalNumber?: string | null;
	isVATPayer?: boolean | null;
	iban?: string | null;
	phoneNumber?: string | null;
	email?: string | null;
	birthDate?: string | null;
	addresses: EnterprisePartyAddressDto[];
};

export enum PartyType {
	NaturalPerson = "NaturalPerson",
	LegalEntity = "LegalEntity",
	SelfEmployed = "SelfEmployed"
};

export type EnterprisePartyAddressDto = {
	type: AddressType;
	municipality: string;
	postalCode: string;
	street?: string | null;
	streetNumber?: string | null;
	orientationNumber?: string | null;
};

export enum AddressType {
	Permanent = "Permanent",
	Mail = "Mail"
};

export type GetCodeListCollectionQueryResult = {
	countries: CodeListItemDto[];
};

export type CodeListItemDto = {
	id: number;
	code: string;
	name: string;
};

export type SignInResult = {
	userInfo: UserInfo;
	isFirstSignIn: boolean;
};

export type UserInfo = {
	token: string;
	login: string;
	firstName: string;
	lastName: string;
	userIdentityProviders: IdentityProvider[];
	accessRightCodes: string[];
	profilePicture?: string | null;
};

export enum IdentityProvider {
	Application = "Application",
	Broker = "Broker"
};

export type ApiProblemDetailsOfAuthError = {
	errors: {[key: string | number]: ErrorDetail[]};
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum AuthError {
	ApplicationVerification = "ApplicationVerification",
	InvalidCredentials = "InvalidCredentials",
	AccountIsDisabled = "AccountIsDisabled",
	PhoneNumberNotFound = "PhoneNumberNotFound",
	SmsSendingFailed = "SmsSendingFailed",
	InvalidToken = "InvalidToken",
	AccountWaitingForEmailConfirmation = "AccountWaitingForEmailConfirmation"
};

export type SignInCommand = {
	login: string;
	password: string;
};

export type SsoSignInRequest = {
	token: string;
	state?: string | null;
	identityProvider: IdentityProvider;
	mode?: AuthMode | null;
	originUrl?: string | null;
};

export enum AuthMode {
	UserToken = "UserToken"
};

const API_URL = import.meta.env.VITE_API_URL;

export type PostUsersFcmTokenFetchResponse = 
| FetchResponse<RegisterFcmTokenCommandResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postUsersFcmToken = (requestContract: RegisterFcmTokenRequest, headers = new Headers()): 
	Promise<PostUsersFcmTokenFetchResponse> => 
	apiPost(`${API_URL}/api/v1/users/fcm-token`, requestContract, headers) as Promise<PostUsersFcmTokenFetchResponse>;

export type DeleteUsersFcmTokenRequestFetchResponse = 
| FetchResponse<DeactivateFcmTokenCommandResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetails, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const deleteUsersFcmTokenRequest = (request: DeactivateFcmTokenRequest, headers = new Headers()): 
	Promise<DeleteUsersFcmTokenRequestFetchResponse> => {
	return apiDelete(`${API_URL}/api/v1/users/fcm-token/${request}`, headers, {}) as Promise<DeleteUsersFcmTokenRequestFetchResponse>;
}

export type GetNoticesUserNoticeListFetchResponse = 
| FetchResponse<EntityListOfUserNoticeListItemDto, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getNoticesUserNoticeList = (offset?: number, limit?: number, headers = new Headers()): 
	Promise<GetNoticesUserNoticeListFetchResponse> => {
	const queryParams = {
		"offset": offset		,
"limit": limit
	}
	return apiGet(`${API_URL}/api/v1/notices/user-notice-list`, headers, queryParams) as Promise<GetNoticesUserNoticeListFetchResponse>;
}

export type PutNoticesUpdateDisplayStatusInUserNoticeFetchResponse = 
| FetchResponse<UpdateDisplayStatusInUserNoticeCommandResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const putNoticesUpdateDisplayStatusInUserNotice = (requestContract: UpdateDisplayStatusInUserNoticeRequest, headers = new Headers()): 
	Promise<PutNoticesUpdateDisplayStatusInUserNoticeFetchResponse> => 
	apiPut(`${API_URL}/api/v1/notices/update-display-status-in-user-notice`, requestContract, headers) as Promise<PutNoticesUpdateDisplayStatusInUserNoticeFetchResponse>;

export type PutNoticesSetSeeForAllNotSeenUserNoticesFetchResponse = 
| FetchResponse<EntityListOfUserNoticeListItemDto, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const putNoticesSetSeeForAllNotSeenUserNotices = (requestContract: SetSeeForAllNotSeenUserNoticesRequest, headers = new Headers()): 
	Promise<PutNoticesSetSeeForAllNotSeenUserNoticesFetchResponse> => 
	apiPut(`${API_URL}/api/v1/notices/set-see-for-all-not-seen-user-notices`, requestContract, headers) as Promise<PutNoticesSetSeeForAllNotSeenUserNoticesFetchResponse>;

export type PostEnterprisesFetchResponse = 
| FetchResponse<void, 204> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postEnterprises = (requestContract: CreateEnterpriseRequest, headers = new Headers()): 
	Promise<PostEnterprisesFetchResponse> => 
	apiPost(`${API_URL}/api/v1/enterprises`, requestContract, headers) as Promise<PostEnterprisesFetchResponse>;

export type GetCodeListsFetchResponse = 
| FetchResponse<GetCodeListCollectionQueryResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getCodeLists = (headers = new Headers()): 
	Promise<GetCodeListsFetchResponse> => {
	return apiGet(`${API_URL}/api/v1/code-lists`, headers, {}) as Promise<GetCodeListsFetchResponse>;
}

export type PostCacheRefreshFetchResponse = 
| FetchResponse<void, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 403> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postCacheRefresh = (headers = new Headers()): 
	Promise<PostCacheRefreshFetchResponse> => 
	apiPost(`${API_URL}/api/v1/cache/refresh`, {}, headers) as Promise<PostCacheRefreshFetchResponse>;

export type PostAuthSignInFetchResponse = 
| FetchResponse<SignInResult, 200> 
| FetchResponse<ApiProblemDetailsOfAuthError, 400> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthSignIn = (requestContract: SignInCommand, headers = new Headers()): 
	Promise<PostAuthSignInFetchResponse> => 
	apiPost(`${API_URL}/api/v1/auth/sign-in`, requestContract, headers) as Promise<PostAuthSignInFetchResponse>;

export type PostAuthSsoFetchResponse = 
| FetchResponse<SignInResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetailsOfAuthError, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthSso = (requestContract: SsoSignInRequest, headers = new Headers()): 
	Promise<PostAuthSsoFetchResponse> => 
	apiPost(`${API_URL}/api/v1/auth/sso`, requestContract, headers) as Promise<PostAuthSsoFetchResponse>;

export type GetAuthUserinfoFetchResponse = 
| FetchResponse<UserInfo, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getAuthUserinfo = (headers = new Headers()): 
	Promise<GetAuthUserinfoFetchResponse> => {
	return apiGet(`${API_URL}/api/v1/auth/userinfo`, headers, {}) as Promise<GetAuthUserinfoFetchResponse>;
}

export const API = { 
	postUsersFcmToken,
	deleteUsersFcmTokenRequest,
	getNoticesUserNoticeList,
	putNoticesUpdateDisplayStatusInUserNotice,
	putNoticesSetSeeForAllNotSeenUserNotices,
	postEnterprises,
	getCodeLists,
	postCacheRefresh,
	postAuthSignIn,
	postAuthSso,
	getAuthUserinfo
}

