import { SignInPage } from "Auth/Pages/SignInPage";
import { Error403Page } from "Infrastructure/Pages/Error403Page";
import { Error404Page } from "Infrastructure/Pages/Error404Page";
import { AuthenticatedRoute } from "Infrastructure/Routing/AuthenticatedRoute";
import { UnauthenticatedRoute } from "Infrastructure/Routing/UnauthenticatedRoute";
import { AppRouting, getRoute } from "Infrastructure/Utils/UrlUtils";
import { NoticeBoardPage } from "Notices/Pages/NoticeBoardPage";
import { CommonTaskGroup, DashboardPage } from "Tasks/Pages/DashboardPage";
import { TaskCreatePage } from "Tasks/Pages/TaskCreatePage";
import { TaskDetailPage } from "Tasks/Pages/TaskDetailPage";
import { TaskGroupPage } from "Tasks/Pages/TaskGroupPage";
import { createBrowserRouter } from "react-router-dom";

const appRoutes = [
	{
		path: getRoute(AppRouting.SignIn),
		element: (
			<UnauthenticatedRoute>
				<SignInPage />
			</UnauthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Tasks.Dashboard),
		element: (
			<AuthenticatedRoute>
				<DashboardPage />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Tasks.Inbox),
		element: (
			<AuthenticatedRoute>
				<TaskGroupPage taskGroup={CommonTaskGroup.Inbox} />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Tasks.Today),
		element: (
			<AuthenticatedRoute>
				<TaskGroupPage taskGroup={CommonTaskGroup.Today} />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Tasks.OtherDays),
		element: (
			<AuthenticatedRoute>
				<TaskGroupPage taskGroup={CommonTaskGroup.OtherDays} />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Tasks.SharedWithMe),
		element: (
			<AuthenticatedRoute>
				<TaskGroupPage taskGroup={CommonTaskGroup.SharedWithMe} />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Tasks.Watched),
		element: (
			<AuthenticatedRoute>
				<TaskGroupPage taskGroup={CommonTaskGroup.Watched} />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Tasks.Tags),
		element: (
			<AuthenticatedRoute>
				<TaskGroupPage taskGroup={CommonTaskGroup.Tags} />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Tasks.Detail),
		element: (
			<AuthenticatedRoute>
				<TaskDetailPage />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Tasks.Create),
		element: (
			<AuthenticatedRoute>
				<TaskCreatePage />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Notifications),
		element: (
			<AuthenticatedRoute>
				<NoticeBoardPage />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Error403),
		element: <Error403Page />,
	},
	{
		path: "*",
		element: <Error404Page />,
	},
];

export const appRouter = createBrowserRouter(appRoutes, {
	basename: import.meta.env.VITE_BASE_URL,
});
