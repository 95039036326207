import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { BlDefaultButton } from "Shared/Buttons/BlDefaultButton";
import { FilterIcon, SearchIcon } from "Shared/Icons";
import { TopBarTitle } from "Shared/Layouts/AuthenticatedLayout";
import { More } from "Tasks/Components/TaskGroup/More";
import type { CommonTaskGroup } from "Tasks/Pages/DashboardPage";
import { useNavigate } from "react-router-dom";

const StyledFilterWrapper = styled(Box)`
  svg {
    margin-bottom: 3px;
  }
`;

export const TopBar = ({ taskGroup }: { taskGroup: CommonTaskGroup }) => {
	const { t } = useResource();

	const navigate = useNavigate();

	return (
		<Grid container alignItems="center" mr={1}>
			<Grid item xs={1.5}>
				<BlDefaultButton color="inherit" onClick={() => navigate(-1)}>
					<ArrowLeftIcon />
				</BlDefaultButton>
			</Grid>
			<Grid item xs textAlign="left">
				<TopBarTitle fontWeight={700} fontSize={16} textAlign="left">
					{t(Resources.Tasks.CommonGroup[taskGroup])}
				</TopBarTitle>
			</Grid>
			<Grid
				item
				xs
				display="flex"
				justifyContent={"flex-end"}
				alignItems={"flex-end"}
			>
				<Box>
					<BlDefaultButton color="inherit">
						<SearchIcon />
					</BlDefaultButton>
				</Box>
				<StyledFilterWrapper>
					<BlDefaultButton color="inherit">
						<FilterIcon />
					</BlDefaultButton>
				</StyledFilterWrapper>
				<More />
			</Grid>
		</Grid>
	);
};
