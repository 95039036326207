export const AppRouting = {
	Error403: {
		path: () => "/403",
	},
	Error404: {
		path: () => "/404",
	},
	SignIn: {
		path: () => "/",
	},
	Tasks: {
		Dashboard: {
			path: () => "/ukoly",
		},
		Inbox: {
			path: () => "/ukoly/inbox",
		},
		Today: {
			path: () => "/ukoly/dnes",
		},
		OtherDays: {
			path: () => "/ukoly/nasledujici",
		},
		SharedWithMe: {
			path: () => "/ukoly/sdilene",
		},
		Watched: {
			path: () => "/ukoly/sledovane",
		},
		Tags: {
			path: () => "/ukoly/tagy",
		},
		Create: {
			path: () => "/ukoly/novy",
		},
		Detail: {
			route: "/ukoly/:id",
			path: (id: string) => `/ukoly/${id}`,
		},
	},
	Notifications: {
		path: () => "/upozorneni",
	},
} as const;

type RouteObject<T extends any[] = any[]> = {
	route?: string;
	path: (...args: T) => string;
};

export function getRoute<T extends RouteObject>(routeObj: T): string {
	return routeObj.route ?? routeObj.path();
}

export function resolveStartPage(
	user: unknown | null,
	unauthenticatedUrl: string | null,
) {
	if (user === null) {
		return AppRouting.SignIn.path();
	}

	if (unauthenticatedUrl) {
		return unauthenticatedUrl;
	}
	return AppRouting.Tasks.Dashboard.path();
}

export function getPathnameAndSearch(location: {
	pathname: string;
	search: string;
}) {
	return `${location.pathname}${location.search}`;
}
