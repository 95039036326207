import * as React from "react";
export const TagIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.16667 1.5L15.6667 9C15.8716 9.22918 15.985 9.52586 15.985 9.83333C15.985 10.1408 15.8716 10.4375 15.6667 10.6667L10.6667 15.6667C10.4375 15.8716 10.1408 15.985 9.83333 15.985C9.52586 15.985 9.22918 15.8716 9 15.6667L1.5 8.16667V4.83333C1.5 3.94928 1.85119 3.10143 2.47631 2.47631C3.10143 1.85119 3.94928 1.5 4.83333 1.5H8.16667Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50065 8.16732C7.42113 8.16732 8.16732 7.42113 8.16732 6.50065C8.16732 5.58018 7.42113 4.83398 6.50065 4.83398C5.58018 4.83398 4.83398 5.58018 4.83398 6.50065C4.83398 7.42113 5.58018 8.16732 6.50065 8.16732Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
