import {
	type NoticeListType,
	useNoticesQuery,
} from "Notices/Api/Queries/useNoticesQuery";
import { NoticeGroup } from "Notices/Components/NoticeGroup";
import { groupBy } from "lodash-es";

type Props = {
	type: NoticeListType;
};

export const NoticeList: React.FunctionComponent<Props> = (props) => {
	const { type } = props;
	const { data } = useNoticesQuery(type);

	const groups = groupBy(data?.items ?? [], (e) => e.entityPublicID);

	return (
		<>
			{Object.keys(groups).map((key) => (
				<NoticeGroup key={key} group={groups[key]} />
			))}
		</>
	);
};
