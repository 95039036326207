import { useQueryClient } from "@tanstack/react-query";
import { useHub } from "Infrastructure/Hooks/useHub";
import { taskKeys } from "Tasks/Api/Keys";
import { useMemo } from "react";

export function useTasksHub() {
	const client = useQueryClient();

	const actions = useMemo(
		() => ({
			TaskChanged: (message: { taskExternalID: string }) => {
				const taskExternalID = message.taskExternalID;
				console.log(
					`Received TaskChanged message for taskExternalID: ${taskExternalID}. Invalidating cache.`,
				);

				client.invalidateQueries({
					queryKey: [taskKeys.detail(taskExternalID)],
				});
			},
		}),
		[client],
	);

	useHub("tasksHub", actions);
}
