import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);

if ("serviceWorker" in navigator) {
	navigator.serviceWorker.register("/firebase-messaging-sw.js");
}
