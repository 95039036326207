import { useHub } from "Infrastructure/Hooks/useHub";
import { unseenNoticeCountAtom } from "Notices/State/NoticesHubAtoms";
import { useAtom } from "jotai";
import { useMemo } from "react";

export function useNoticesHub() {
	const [, setUnseenNoticeCount] = useAtom(unseenNoticeCountAtom);

	const actions = useMemo(
		() => ({
			UnseenNoticeCount: (message: { unseenNoticesCount: number }) => {
				setUnseenNoticeCount(message.unseenNoticesCount);
			},
		}),
		[setUnseenNoticeCount],
	);

	useHub("noticesHub", actions);
}
