import {
	Divider,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	styled,
} from "@mui/material";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { enumToArray } from "Infrastructure/Utils/ObjectUtils";
import { AppRouting } from "Infrastructure/Utils/UrlUtils";
import {
	CalendarIcon,
	ClockIcon,
	EyeCheckIcon,
	RulerIcon,
	StarIcon,
	TagIcon,
} from "Shared/Icons";
import { AuthenticatedLayout } from "Shared/Layouts/AuthenticatedLayout";
import { TopBar } from "Tasks/Components/Dashboard/TopBar";
import { useNavigate } from "react-router-dom";

const PageResources = Resources.Tasks.Dashboard;

export enum CommonTaskGroup {
	Inbox = "Inbox",
	Today = "Today",
	OtherDays = "OtherDays",
	SharedWithMe = "SharedWithMe",
	Watched = "Watched",
	Tags = "Tags",
}

const Count = styled(ListItemText)`
  p {
    text-align: right;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.border};
  }
`;

const StyledListItemButton = styled(ListItemButton)`
  padding: ${({ theme }) => theme.spacing(0.25)} 0;
  span {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  svg {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const DashboardPage: React.FunctionComponent = () => {
	const { t } = useResource();
	const navigate = useNavigate();

	return (
		<AuthenticatedLayout documentTitle={null} topBar={<TopBar />}>
			<List>
				{enumToArray(CommonTaskGroup).map((group) => (
					<StyledListItemButton
						key={group}
						onClick={() => navigate(getUrl(group as CommonTaskGroup))}
					>
						<ListItemIcon
							sx={{
								minWidth: "30px",
							}}
						>
							{renderIcon(group as CommonTaskGroup)}
						</ListItemIcon>
						<ListItemText primary={t(Resources.Tasks.CommonGroup[group])} />
						<Count secondary={"1"} />
					</StyledListItemButton>
				))}
			</List>

			<Divider />

			<Typography mt={2} fontWeight={600}>
				{t(PageResources.CustomGroup.Title)}
			</Typography>
		</AuthenticatedLayout>
	);
};

function getUrl(group: CommonTaskGroup) {
	switch (group) {
		case CommonTaskGroup.Inbox:
			return AppRouting.Tasks.Inbox.path();
		case CommonTaskGroup.Today:
			return AppRouting.Tasks.Today.path();
		case CommonTaskGroup.OtherDays:
			return AppRouting.Tasks.OtherDays.path();
		case CommonTaskGroup.SharedWithMe:
			return AppRouting.Tasks.SharedWithMe.path();
		case CommonTaskGroup.Watched:
			return AppRouting.Tasks.Watched.path();
		case CommonTaskGroup.Tags:
			return AppRouting.Tasks.Tags.path();
		default:
			return AppRouting.Tasks.Dashboard.path();
	}
}

function renderIcon(icon: CommonTaskGroup) {
	switch (icon) {
		case CommonTaskGroup.Inbox:
			return <RulerIcon />;
		case CommonTaskGroup.Today:
			return <CalendarIcon />;
		case CommonTaskGroup.OtherDays:
			return <ClockIcon />;
		case CommonTaskGroup.SharedWithMe:
			return <EyeCheckIcon />;
		case CommonTaskGroup.Watched:
			return <StarIcon />;
		case CommonTaskGroup.Tags:
			return <TagIcon />;
		default:
			return <TagIcon />;
	}
}
