import * as React from "react";
export const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={26}
      height={24}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.0085 21L15.7205 15M17.8165 10C17.8165 13.866 14.5321 17 10.4805 17C6.42897 17 3.14453 13.866 3.14453 10C3.14453 6.13401 6.42897 3 10.4805 3C14.5321 3 17.8165 6.13401 17.8165 10Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
