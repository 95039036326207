import { Box, Grid } from "@mui/material";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { BlDefaultButton } from "Shared/Buttons/BlDefaultButton";
import { BellIcon, SearchIcon, SettingsIcon } from "Shared/Icons";
import { CrossIcon } from "Shared/Icons/Cross";
import {
	AuthenticatedLayout,
	TopBarTitle,
} from "Shared/Layouts/AuthenticatedLayout";
import { TaskForm } from "Tasks/Components/Detail/TaskForm";
import { useNavigate } from "react-router-dom";

const PageResources = Resources.Tasks.Create;

const TopBar = () => {
	const { t } = useResource();
	const navigate = useNavigate();

	return (
		<Grid container alignItems="center" justifyContent="space-between" pl={2}>
			<Grid
				item
				xs
				display="flex"
				justifyContent="flex-start"
				alignItems="center"
			>
				<Box />
			</Grid>
			<Grid item xs display="flex" justifyContent="center" alignItems="center">
				<TopBarTitle fontWeight={700} fontSize={16}>
					{t(PageResources.Title)}
				</TopBarTitle>
			</Grid>
			<Grid
				item
				xs
				display="flex"
				justifyContent="flex-end"
				alignItems="center"
			>
				<BlDefaultButton color="inherit" onClick={() => navigate(-1)}>
					<CrossIcon />
				</BlDefaultButton>
			</Grid>
		</Grid>
	);
};

export const TaskCreatePage: React.FunctionComponent = (_) => {
	const { t } = useResource();
	return (
		<AuthenticatedLayout
			documentTitle={t(PageResources.Title)}
			topBar={<TopBar />}
		>
			<TaskForm />
		</AuthenticatedLayout>
	);
};
