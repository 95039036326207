import { getMessaging, getToken } from "firebase/messaging";
import { useEffect, useState } from "react";

export function useFcmToken() {
	const messaging = getMessaging();
	const [token, setToken] = useState<string | null>(null);

	useEffect(() => {
		getToken(messaging).then((t) => setToken(t));
	}, [messaging]);

	return token;
}
