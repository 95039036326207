import styled from "@emotion/styled";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { NoticeType, type UserNoticeListItemDto } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { formatDate } from "Infrastructure/Utils/DateUtils";
import {
	CircleCheckIcon,
	LayoutListIcon,
	MentionIcon,
	MessageIcon,
	PlusMathIcon,
} from "Shared/Icons";
import { parseISO } from "date-fns";
import { minBy } from "lodash-es";

const GreyCircleIcon = styled(Box)`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.gray};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export enum ExtendedNoticeType {
	MultipleChanges = "MultipleChanges",
}

export const NoticeGroupType = {
	...NoticeType,
	...ExtendedNoticeType,
};

export type NoticeGroupType =
	(typeof NoticeGroupType)[keyof typeof NoticeGroupType];

const PageResources = Resources.Notices;

type Props = {
	key: string;
	group: UserNoticeListItemDto[];
};

export const NoticeGroup: React.FunctionComponent<Props> = (props) => {
	const { group } = props;
	const { t } = useResource();

	const isMultiple = group.length > 1;
	const first = group[0];

	const type = isMultiple ? NoticeGroupType.MultipleChanges : first.type;
	const title = isMultiple
		? t(PageResources.MultipleChanges)
		: t(PageResources.Types[first.type]);

	const minDateCreated = minBy(group, (e) => e.dateCreated);

	return (
		<Box>
			<Grid
				container
				spacing={2}
				alignItems="center"
				justifyContent="center"
				pt={2}
				pb={1}
			>
				<Grid item>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<GreyCircleIcon>{renderIcon(type)}</GreyCircleIcon>
					</Box>
				</Grid>
				<Grid item xs mb={1}>
					<Stack direction="row" gap={1} alignItems="end">
						<Typography fontWeight={600} fontSize={14}>
							{title}
						</Typography>
						<Typography color="primary" fontWeight={500} fontSize={12}>
							{renderDate(t, minDateCreated?.dateCreated ?? "")}
						</Typography>
					</Stack>

					{/* <Typography>{notification.entityName}</Typography> */}
				</Grid>
			</Grid>
			<Divider />
		</Box>
	);
};

function renderDate(t: ReturnType<typeof useResource>["t"], date: string) {
	const parsedDate = parseISO(date);
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);

	if (parsedDate.toDateString() === today.toDateString()) {
		return t(Resources.Common.Today);
	}
	if (parsedDate.toDateString() === yesterday.toDateString()) {
		return t(Resources.Common.Yesterday);
	}

	return formatDate(parsedDate);
}

function renderIcon(type: NoticeGroupType) {
	switch (type) {
		case NoticeGroupType.AssignedToUser:
			return <PlusMathIcon />;
		case NoticeGroupType.CommentaryAdded:
			return <MessageIcon />;
		case NoticeGroupType.MentionedInComment:
			return <MentionIcon />;
		case NoticeGroupType.WorkflowStepResolved:
			return <CircleCheckIcon />;
		case NoticeGroupType.MultipleChanges:
			return <LayoutListIcon />;
		default:
			return <LayoutListIcon />;
	}
}
