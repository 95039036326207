import { useQueryClient } from "@tanstack/react-query";
import { usePrevious } from "Infrastructure/Hooks/usePrevious";
import { noticeKeys } from "Notices/Api/Keys";
import { unseenNoticeCountAtom } from "Notices/State/NoticesHubAtoms";
import { useAtom } from "jotai";
import { useEffect } from "react";

export function useWatchNotices() {
	const [count] = useAtom(unseenNoticeCountAtom);

	const previousCount = usePrevious(count);

	const client = useQueryClient();

	useEffect(() => {
		if (previousCount === count) {
			return;
		}

		client.invalidateQueries({
			queryKey: noticeKeys.all,
		});
	}, [count, previousCount, client]);
}
