import * as React from "react";
export const MessageIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0007 16.666L7.50065 14.166H5.83398C5.17094 14.166 4.53506 13.9026 4.06622 13.4338C3.59738 12.9649 3.33398 12.3291 3.33398 11.666V6.66602C3.33398 6.00297 3.59738 5.36709 4.06622 4.89825C4.53506 4.42941 5.17094 4.16602 5.83398 4.16602H14.1673C14.8304 4.16602 15.4662 4.42941 15.9351 4.89825C16.4039 5.36709 16.6673 6.00297 16.6673 6.66602V11.666C16.6673 12.3291 16.4039 12.9649 15.9351 13.4338C15.4662 13.9026 14.8304 14.166 14.1673 14.166H12.5007L10.0007 16.666Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66797 7.5H13.3346"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66797 10.832H11.668"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
