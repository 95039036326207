import { useMutation } from "@tanstack/react-query";
import {
	type DeactivateFcmTokenRequest,
	deleteUsersFcmTokenRequest,
} from "Infrastructure/Api/Api";

export const DELETE_FCM_TOKEN_MUTATION_KEY = ["DELETE_FCM_TOKEN"];

export function useDeleteFcmTokenMutation() {
	return useMutation({
		mutationKey: DELETE_FCM_TOKEN_MUTATION_KEY,
		mutationFn: (data: DeactivateFcmTokenRequest) =>
			deleteUsersFcmTokenRequest(data),
	});
}
