import { useIsAuthenticated } from "Auth/Hooks/useIsAuthenticated";
import { notifyAuthChanged } from "Auth/Utils/AuthUtils";
import { useFirebaseInit } from "Infrastructure/Hooks/Firebase/useFirebaseInit";
import { useFcmTokenSynchronization } from "Notifications/Hooks/useFcmTokenSynchronization";
import {
	type FunctionComponent,
	type PropsWithChildren,
	useEffect,
} from "react";

export const NotificationsProvider: FunctionComponent<PropsWithChildren> = (
	props,
) => {
	useFirebaseInit();
	useFcmTokenSynchronization();

	const isAuthenticated = useIsAuthenticated();

	useEffect(() => {
		notifyAuthChanged(isAuthenticated);
	}, [isAuthenticated]);

	return <div>{props.children}</div>;
};
