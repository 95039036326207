import { useNoticesHub } from "Notices/Hooks/useNoticesHub";
import { useWatchNotices } from "Notices/Hooks/useWatchNotices";
import type { FunctionComponent, PropsWithChildren } from "react";

export const NoticesProvider: FunctionComponent<PropsWithChildren> = (
	props,
) => {
	useNoticesHub();
	useWatchNotices();

	return <div>{props.children}</div>;
};
