import { useQuery } from "@tanstack/react-query";
import { getNoticesUserNoticeList } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";
import { noticeKeys } from "Notices/Api/Keys";

export enum NoticeListType {
	Unread = "Unread",
	All = "All",
}

export function useNoticesQuery(type: NoticeListType) {
	return useQuery({
		queryKey: noticeKeys.list(type),
		queryFn: processResponse(() => getNoticesUserNoticeList(0, 10)),
	});
}
