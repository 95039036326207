import styled from "@emotion/styled";
import { AppBar, Box, type Theme, Toolbar, Typography } from "@mui/material";
import { useOnMessage } from "Infrastructure/Hooks/Firebase/useOnMessage";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { useNoticesHub } from "Notices/Hooks/useNoticesHub";
import { useWatchNotices } from "Notices/Hooks/useWatchNotices";
import { useFcmTokenSynchronization } from "Notifications/Hooks/useFcmTokenSynchronization";
import { useAtom } from "jotai";
import { type FC, type PropsWithChildren, useEffect } from "react";
import { toast } from "sonner";

const StyledBox = styled(Box)`
  display: flex;
`;

const StyledAppBar = styled(AppBar)(
	({ theme }: { theme: Theme }) => `
  z-index: ${theme.zIndex.drawer + 1};
  background-color: ${theme.palette.background.paper};
  color: ${theme.palette.text.primary};
  box-shadow: 0 1px 1px 0 ${theme.colors.gray};
`,
);

const StyledMainContent = styled(Box)`
  flex-grow: 1;
  padding: 16px;
`;

export const TopBarTitle = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
`;

const StyledToolbar = styled(Toolbar)`
  padding: 0;
`;

type Props = {
	documentTitle: string | null;
	topBar: JSX.Element;
};

export const AuthenticatedLayout: FC<PropsWithChildren<Props>> = ({
	documentTitle,
	topBar,
	children,
}) => {
	const { t } = useResource();

	useEffect(() => {
		if (documentTitle === null) {
			document.title = t(Resources.Common.AppName);
			return;
		}

		if (!!documentTitle) {
			document.title = `${documentTitle} | ${t(Resources.Common.AppName)}`;
		} else {
			document.title = t(Resources.Common.AppName);
		}
	}, [documentTitle, t]);

	useOnMessage((p) => {
		toast.success(p.notification.title, {
			description: p.notification.body,
			position: "top-center",
		});
	});

	useWatchNotices();
	useFcmTokenSynchronization();

	return (
		<StyledBox>
			<StyledAppBar position="fixed">
				<StyledToolbar>{topBar}</StyledToolbar>
			</StyledAppBar>

			<StyledMainContent component="main">
				<Toolbar />
				{children}
			</StyledMainContent>
		</StyledBox>
	);
};
