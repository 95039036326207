import { getMessaging, onMessage } from "firebase/messaging";
import { useEffect } from "react";

export const useOnMessage = (onReceiveNotification: (payload: any) => void) => {
	useEffect(() => {
		const messaging = getMessaging();

		const unsubscribe = onMessage(messaging, (payload) => {
			console.log("Správa prijatá. ", payload);
			onReceiveNotification(payload);
		});

		return unsubscribe;
	}, [onReceiveNotification]);
};
