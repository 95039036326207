import * as React from "react";
export const BellIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={26}
      height={24}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.2791 17V18C10.2791 18.7956 10.6103 19.5587 11.1999 20.1213C11.7895 20.6839 12.5892 21 13.4231 21C14.2569 21 15.0566 20.6839 15.6462 20.1213C16.2358 19.5587 16.5671 18.7956 16.5671 18V17M11.3271 5C11.3271 4.46957 11.5479 3.96086 11.941 3.58579C12.334 3.21071 12.8672 3 13.4231 3C13.979 3 14.5121 3.21071 14.9052 3.58579C15.2982 3.96086 15.5191 4.46957 15.5191 5C16.7226 5.54303 17.7486 6.38833 18.4871 7.4453C19.2255 8.50227 19.6486 9.73107 19.7111 11V14C19.7899 14.6217 20.0207 15.2171 20.3847 15.7381C20.7488 16.2592 21.236 16.6914 21.8071 17H5.03906C5.61016 16.6914 6.09734 16.2592 6.46139 15.7381C6.82545 15.2171 7.0562 14.6217 7.13506 14V11C7.19748 9.73107 7.6206 8.50227 8.35907 7.4453C9.09755 6.38833 10.1235 5.54303 11.3271 5Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
