import { useIsAuthenticated } from "Auth/Hooks/useIsAuthenticated";
import { useSaveFcmTokenMutation } from "Notifications/Api/Mutations/saveFcmTokenMutation";
import { useFcmToken } from "Notifications/Hooks/useFcmToken";
import { useEffect } from "react";

export async function useFcmTokenSynchronization() {
	const fcmToken = useFcmToken();

	const isAuthenticated = useIsAuthenticated();

	const { mutate } = useSaveFcmTokenMutation();

	useEffect(() => {
		if (!!fcmToken && isAuthenticated) {
			mutate({ token: fcmToken });
		}
	}, [fcmToken, mutate, isAuthenticated]);
}
