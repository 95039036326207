import styled from "@emotion/styled";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { AppRouting } from "Infrastructure/Utils/UrlUtils";
import { BlDefaultButton } from "Shared/Buttons/BlDefaultButton";
import { MoreIcon, PlusMathIcon, SaveIcon, ShareIcon } from "Shared/Icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const PageResources = Resources.Tasks.TaskGroup.More;

const MenuIcon = styled(ListItemIcon)`
  svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const More = () => {
	const { t } = useResource();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<BlDefaultButton color="inherit" onClick={handleClick}>
				<MoreIcon />
			</BlDefaultButton>

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				sx={{
					marginTop: "40px",
				}}
			>
				<MenuItem
					onClick={() => {
						handleClose();
						navigate(AppRouting.Tasks.Create.path());
					}}
				>
					<MenuIcon>
						<PlusMathIcon />
					</MenuIcon>
					{t(PageResources.CreateTask)}
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<MenuIcon>
						<ShareIcon />
					</MenuIcon>
					{t(PageResources.ShareTaskGroup)}
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<MenuIcon>
						<SaveIcon />
					</MenuIcon>
					{t(PageResources.ArchiveTaskGroup)}
				</MenuItem>
			</Menu>
		</>
	);
};
