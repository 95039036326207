import { type QueryClient, useQueryClient } from "@tanstack/react-query";
import { USER_INFO_QUERY_KEY } from "Auth/Api/Queries/useUserInfoQuery";
import { JWT_KEY } from "Auth/Models/AuthConstants";
import { useDeleteFcmTokenMutation } from "Notifications/Api/Mutations/deleteFcmTokenMutation";
import { useFcmToken } from "Notifications/Hooks/useFcmToken";

export function useSignOut() {
	const fcmToken = useFcmToken();
	const queryClient = useQueryClient();
	const { mutate: deleteToken } = useDeleteFcmTokenMutation();

	return () => {
		processSignOut(queryClient, deleteToken, fcmToken);
	};
}

export function processSignOut(
	queryClient: QueryClient,
	deleteToken: ReturnType<typeof useDeleteFcmTokenMutation>["mutate"],
	fcmToken: ReturnType<typeof useFcmToken>,
) {
	const jwt = localStorage.getItem(JWT_KEY);

	if (!jwt) {
		return;
	}

	localStorage.removeItem(JWT_KEY);

	queryClient.invalidateQueries({ queryKey: [USER_INFO_QUERY_KEY] });

	if (!!fcmToken) {
		deleteToken({ token: fcmToken });
	}
}
