import type { HubConnection } from "@microsoft/signalr";
import { useIsAuthenticated } from "Auth/Hooks/useIsAuthenticated";
import { buildHub } from "Infrastructure/Utils/SignalRUtils";
import { produce } from "immer";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";

type ConnectionsState = {
	tasksHub: HubConnection | null;
	noticesHub: HubConnection | null;
};

export const connectionsAtom = atom<ConnectionsState>({
	tasksHub: null,
	noticesHub: null,
});

interface IHubActions {
	[eventName: string]: (message: any) => void;
}

export const useHub = (
	hubName: keyof ConnectionsState,
	actions: IHubActions,
) => {
	const isAuthenticated = useIsAuthenticated();
	const [connections, setConnections] = useAtom(connectionsAtom);
	const connection = connections[hubName];

	useEffect(() => {
		const connect = async () => {
			if (!!connection || !isAuthenticated) {
				return;
			}

			console.log(`Starting SignalR connection to '${hubName}'`);
			const newConnection = buildHub(hubName);

			// Register event handlers
			for (const [eventName, handler] of Object.entries(actions)) {
				newConnection.on(eventName, handler);
			}

			try {
				await newConnection.start();
				setConnections(
					produce((draft) => {
						draft[hubName] = newConnection;
					}),
				);
			} catch (err) {
				console.error("SignalR connection error:", err);
			}
		};

		connect();

		return () => {
			if (connection) {
				console.log(`Stopping SignalR connection to '${hubName}'`);
				connection.stop().then(() => {
					setConnections(
						produce((draft) => {
							delete draft[hubName];
						}),
					);
				});
			}
		};
	}, [connection, isAuthenticated, hubName, actions, setConnections]);

	return connection;
};
