import { Typography } from "@mui/material";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { AppRouting } from "Infrastructure/Utils/UrlUtils";
import { AuthenticatedLayout } from "Shared/Layouts/AuthenticatedLayout";
import { UnstyledLink } from "Shared/Routing/UnstyledLink";
import { TopBar } from "Tasks/Components/TaskGroup/TopBar";
import type { CommonTaskGroup } from "Tasks/Pages/DashboardPage";

type Props = { taskGroup: CommonTaskGroup };

export const TaskGroupPage: React.FunctionComponent<Props> = (props) => {
	const { taskGroup } = props;
	const { t } = useResource();

	return (
		<AuthenticatedLayout
			documentTitle={t(Resources.Tasks.CommonGroup[taskGroup])}
			topBar={<TopBar taskGroup={taskGroup} />}
		>
			{taskGroup}

			<UnstyledLink to={AppRouting.Tasks.Detail.path("123")}>
				<Typography variant="h1">Detail - just for test</Typography>
			</UnstyledLink>
		</AuthenticatedLayout>
	);
};
