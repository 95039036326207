import * as React from "react";
export const EyeCheckIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.9987 11.6654C10.9192 11.6654 11.6654 10.9192 11.6654 9.9987C11.6654 9.07822 10.9192 8.33203 9.9987 8.33203C9.07822 8.33203 8.33203 9.07822 8.33203 9.9987C8.33203 10.9192 9.07822 11.6654 9.9987 11.6654Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99935 15.8327C6.66602 15.8327 3.88852 13.8885 1.66602 9.99935C3.88852 6.11018 6.66602 4.16602 9.99935 4.16602C13.3327 4.16602 16.1102 6.11018 18.3327 9.99935C17.9827 10.6127 17.6177 11.1777 17.2402 11.6935"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 15.8327L14.1667 17.4993L17.5 14.166"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
