import * as React from "react";
export const RulerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.834 5.41732V1.38398C13.834 1.08023 13.5654 0.833984 13.234 0.833984H6.43398C6.10261 0.833984 5.83398 1.08023 5.83398 1.38398V18.6173C5.83398 18.9211 6.10261 19.1673 6.43398 19.1673H13.234C13.5654 19.1673 13.834 18.9211 13.834 18.6173V14.584M13.834 5.41732H10.834M13.834 5.41732V10.0007M13.834 14.584V10.0007M13.834 14.584H10.834M13.834 10.0007H10.834"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
