import { zodResolver } from "@hookform/resolvers/zod";
import { BlFormInput } from "Forms/Components/BlFormInput";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { BlButton } from "Shared/Buttons/BlButton";
import { useForm } from "react-hook-form";
import { z } from "zod";

const FormDataSchema = z.object({
	name: z.string().min(1),
	description: z.string(),
});

type FormData = z.infer<typeof FormDataSchema>;

type Props = {};

export const TaskForm: React.FunctionComponent<Props> = () => {
	const { t } = useResource();

	const form = useForm({
		resolver: zodResolver(FormDataSchema),
		defaultValues: {
			name: "",
			description: "",
		},
	});
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = form;

	const submit = (data: FormData) => {
		console.log(data);
	};

	return (
		<>
			<form onSubmit={handleSubmit(submit)}>
				<BlFormInput
					control={control}
					name="name"
					label="Name"
					autoComplete="name"
					errors={errors}
				/>

				<BlFormInput
					control={control}
					name="description"
					label="Description"
					autoComplete="description"
					errors={errors}
				/>

				<BlButton type="submit" color="primary" fullWidth>
					{t(Resources.Common.Save)}
				</BlButton>
			</form>
		</>
	);
};
