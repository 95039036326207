import styled from "@emotion/styled";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { NoticeListType } from "Notices/Api/Queries/useNoticesQuery";
import { unseenNoticeCountAtom } from "Notices/State/NoticesHubAtoms";
import { useAtom } from "jotai";

const Group = styled(ToggleButtonGroup)`
  &.MuiToggleButtonGroup-root {
    overflow: hidden;
    border-radius: 12px;
    width: 100%;
  }

  .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    border-radius: 0;
  }

  .MuiToggleButtonGroup-grouped:first-of-type {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .MuiToggleButtonGroup-grouped:last-of-type {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

const Button = styled(ToggleButton)`
  text-transform: none;
  background-color: ${({ theme }) => theme.colors.gray};
  flex-grow: 1;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.MuiToggleButton-root.Mui-selected {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;

type Props = {
	type: NoticeListType;
	onToggle: (type: NoticeListType) => void;
};

export const NoticeTypeToggle: React.FunctionComponent<Props> = (props) => {
	const { onToggle, type } = props;
	const [notificationCount] = useAtom(unseenNoticeCountAtom);

	return (
		<Group
			value={type}
			exclusive
			onChange={(_, value) => {
				if (!!value) {
					onToggle(value);
				}
			}}
			aria-label="text alignment"
		>
			<Button disableRipple value={NoticeListType.Unread}>
				Nepřečtené ({notificationCount})
			</Button>
			<Button disableRipple value={NoticeListType.All}>
				Vše
			</Button>
		</Group>
	);
};
