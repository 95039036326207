import { HubConnectionState } from "@microsoft/signalr";
import { connectionsAtom } from "Infrastructure/Hooks/useHub";
import { useAtom } from "jotai";
import { useEffect } from "react";

export function useTaskUpdateSubscription(taskPublicID: string | undefined) {
	const [connections] = useAtom(connectionsAtom);
	const taskHub = connections.tasksHub;

	useEffect(() => {
		if (!taskPublicID || !taskHub) {
			return;
		}

		console.log("Subscribing to task", taskPublicID);
		taskHub.invoke("SubscribeToTask", taskPublicID);

		return () => {
			if (!!taskHub && taskHub.state === HubConnectionState.Connected) {
				console.log("Unsubscribing from task", taskPublicID);
				taskHub.invoke("UnsubscribeFromTask", taskPublicID);
			}
		};
	}, [taskPublicID, taskHub]);
}
