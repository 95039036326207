import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { NoticeListType } from "Notices/Api/Queries/useNoticesQuery";
import { NoticeList } from "Notices/Components/NoticeList";
import { NoticeTypeToggle } from "Notices/Components/NoticeTypeToggle";
import { TopBar } from "Notices/Components/TopBar";
import { AuthenticatedLayout } from "Shared/Layouts/AuthenticatedLayout";
import { useState } from "react";

const PageResource = Resources.Notices;

export const NoticeBoardPage: React.FunctionComponent = (_) => {
	const { t } = useResource();

	const [type, setType] = useState<NoticeListType>(NoticeListType.Unread);

	return (
		<AuthenticatedLayout
			documentTitle={t(PageResource.Title)}
			topBar={<TopBar />}
		>
			<NoticeTypeToggle type={type} onToggle={setType} />

			<NoticeList type={type} />
		</AuthenticatedLayout>
	);
};
