import { useMutation } from "@tanstack/react-query";
import {
	type RegisterFcmTokenRequest,
	postUsersFcmToken,
} from "Infrastructure/Api/Api";

export const SAVE_FCM_TOKEN_MUTATION_KEY = ["SAVE_FCM_TOKEN"];

export function useSaveFcmTokenMutation() {
	return useMutation({
		mutationKey: SAVE_FCM_TOKEN_MUTATION_KEY,
		mutationFn: (data: RegisterFcmTokenRequest) => postUsersFcmToken(data),
	});
}
