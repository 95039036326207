import { useTaskUpdateSubscription } from "Tasks/Hooks/useTaskUpdateSubscription";
import { useParams } from "react-router-dom";

type Props = {};

export const TaskDetailPage: React.FunctionComponent<Props> = () => {
	const { id: publicID } = useParams<{ id: string }>();

	useTaskUpdateSubscription(publicID);

	return <>TaskDetailPage {publicID}</>;
};
