import { ThemeProvider } from "@emotion/react";
import { ScopedCssBaseline } from "@mui/material";
import {
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { USER_INFO_QUERY_KEY } from "Auth/Api/Queries/useUserInfoQuery";
import { processSignOut } from "Auth/Hooks/useSignOut";
import { JWT_KEY } from "Auth/Models/AuthConstants";
import { configureApiCalls } from "Infrastructure/Api/Api";
import { handleResponseError } from "Infrastructure/Api/ApiUtils";
import { getTheme } from "Infrastructure/Themes/Themes";
import { CzechResourcesDictionary } from "Infrastructure/Translations/CzechResourcesDictionary";
import { initSentry } from "Infrastructure/Utils/SentryUtils";
import { trackUserIdentification } from "Infrastructure/Utils/TrackingUtils";
import { NoticesProvider } from "Notices/Components/NoticesProvider";
import { NotificationsProvider } from "Notifications/Components/NotificationsProvider";
import { TasksProvider } from "Tasks/Components/TasksProvider";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "sonner";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import { appRouter } from "./Infrastructure/Routing/AppRouter";

function App() {
	initSentry();

	const theme = getTheme();
	i18n.use(initReactI18next).init({
		resources: {
			cs: CzechResourcesDictionary,
		},
		lng: "cs",
		fallbackLng: "cs",
		interpolation: {
			escapeValue: false,
		},
	});

	z.setErrorMap(zodI18nMap);

	configureApiCalls({
		jwtKey: JWT_KEY,
		onResponse: (r) => {
			if (r.status === 401 && localStorage.getItem(JWT_KEY) !== null) {
				try {
					const url = r.args[0];
					const isUserInfo = url.endsWith("/auth/userinfo");
					if (!isUserInfo) {
						queryClient.invalidateQueries({ queryKey: [USER_INFO_QUERY_KEY] });
					}
				} catch (_error) {}
			} else {
				trackUserIdentification();
			}
		},
	});

	const queryClient = new QueryClient({
		defaultOptions: {
			mutations: {
				onError: handleResponseError,
			},
		},
		queryCache: new QueryCache({
			onError: handleResponseError,
		}),
	});

	return (
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
			<ThemeProvider theme={theme}>
				<Toaster richColors position="top-left" />
				<ScopedCssBaseline>
					<NotificationsProvider>
						<NoticesProvider>
							<TasksProvider>
								<RouterProvider router={appRouter} />
							</TasksProvider>
						</NoticesProvider>
					</NotificationsProvider>
				</ScopedCssBaseline>
			</ThemeProvider>
		</QueryClientProvider>
	);
}
export default App;
