import * as React from "react";
export const CalendarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.0007 4.16602H5.00065C4.08018 4.16602 3.33398 4.91221 3.33398 5.83268V15.8327C3.33398 16.7532 4.08018 17.4993 5.00065 17.4993H15.0007C15.9211 17.4993 16.6673 16.7532 16.6673 15.8327V5.83268C16.6673 4.91221 15.9211 4.16602 15.0007 4.16602Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.334 2.5V5.83333"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66797 2.5V5.83333"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33398 9.16602H16.6673"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
