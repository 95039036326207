import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { JWT_KEY } from "Auth/Models/AuthConstants";
import { API_URL } from "Infrastructure/Api/ApiUtils";

export function buildHub(endpoint: string) {
	return new HubConnectionBuilder()
		.withUrl(`${API_URL}/${endpoint}`, {
			accessTokenFactory: () => {
				const accessToken =
					localStorage.getItem(JWT_KEY)?.replace("Bearer ", "") ?? "";
				return accessToken;
			},
			withCredentials: false,
			logger: LogLevel.Error,
		})
		.withAutomaticReconnect()
		.build();
}
