import * as React from "react";
export const LayoutListIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.0007 3.33398H5.00065C4.08018 3.33398 3.33398 4.08018 3.33398 5.00065V6.66732C3.33398 7.58779 4.08018 8.33398 5.00065 8.33398H15.0007C15.9211 8.33398 16.6673 7.58779 16.6673 6.66732V5.00065C16.6673 4.08018 15.9211 3.33398 15.0007 3.33398Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0007 11.6673H5.00065C4.08018 11.6673 3.33398 12.4135 3.33398 13.334V15.0007C3.33398 15.9211 4.08018 16.6673 5.00065 16.6673H15.0007C15.9211 16.6673 16.6673 15.9211 16.6673 15.0007V13.334C16.6673 12.4135 15.9211 11.6673 15.0007 11.6673Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
