import { initializeApp } from "firebase/app";
import { useEffect, useState } from "react";

const firebaseConfig = {
	apiKey: "AIzaSyDIJFUeb0J4rO_xVwXP71x7SMIttbbjExw",
	authDomain: "ukolovnik-c7988.firebaseapp.com",
	projectId: "ukolovnik-c7988",
	storageBucket: "ukolovnik-c7988.appspot.com",
	messagingSenderId: "360704583757",
	appId: "1:360704583757:web:8dfbdfffd16c5da126d404",
	measurementId: "G-1BZ2T75JCM",
};

export function useFirebaseInit() {
	const [app] = useState(() => initializeApp(firebaseConfig));

	useEffect(() => {
		async function requestPermissionAndSubscribe() {
			try {
				const permission = await Notification.requestPermission();

				if (permission === "granted") {
					// console.log("Notification permission granted.");
					// onMessage(messaging, (payload) => {
					//   console.log("Message received. ", payload);
					//   // Process your message as required
					// });
				} else {
					// toast.error(
					// 	getTranslation(Resources.PushNotifications.Grand.NotGranted),
					// );
					console.log("Notification permission denied.");
				}
			} catch (error) {
				console.error("Error setting up notifications:", error);
			}
		}

		requestPermissionAndSubscribe();
	}, []);

	return app;
}
